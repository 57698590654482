/*=============================================================================
 App.scss - 

 (C) 2021 SpacetimeQ INC
=============================================================================*/
@import 'library';

.App {
  text-align: left;
  height: 100vh;
}

// cannot be overridden; add attributes to firebase emulator warning
.firebase-emulator-warning {
  // display: none;
  font-size: 4px;
  opacity: .5;
}

// Downloaded Fonts
@font-face {
  font-family: 'digital-7';
  src: url('/asset/digital7mono.ttf');
}
.FontDigital {
  font-family: 'digital-7', 'Orbitron', sans-serif;
  // color: var(---stq-color-text);
  text-shadow:
     0    0   2px blue,
     0    0   3px yellow,
     0    0   4px white;
    // -1px -1px 4px blue,
    //  1px  1px 4px blue;
}
.FontDigitalN {
  font-family: 'digital-7', 'Orbitron', sans-serif;
  text-shadow:
     0    0   1px blue,
     1px  1px 1px rgba(0,0,0,.7);
     // 2px  2px 4px blue;
}
// Google Web Fonts
@import url('https://fonts.googleapis.com/css2?family=Fredericka+the+Great&family=Lato&family=New+Tegomin&family=Reggae+One&family=Sawarabi+Mincho&family=Special+Elite&display=swap');
.FontFredericka { font-family: 'Fredericka the Great', cursive; }
.FontLato       { font-family: 'Lato', sans-serif; }
.FontTegomin    { font-family: 'New Tegomin', serif; }
.FontReggae     { font-family: 'Reggae One', cursive; }
.FontMincho     { font-family: 'Sawarabi Mincho', sans-serif; }
.FontElite      { font-family: 'Special Elite', cursive; }
.FontArial      { font-family: 'Arial', sans-serif; }
.FontArialBold  { font-family: 'Arial Bold', sans-serif; }

/*---------------------------------------------------------------------------
 stqm Navbar.tsx
---------------------------------------------------------------------------*/
:root {
  --navbar-h:  2.3rem;  // orig. h-16 (4rem) for menu bar -Desktop,
  background-color: var(--stq-color-background);
}
.Sq_navbar-h {
  height: var(--navbar-h);
}

.Sq_h-max { // maximum height for the user area
  height: calc(100vh - var(--navbar-h));
}

/*---------------------------------------------------------------------------
 stqm UserMenu.tsx - Popup menu on hover
---------------------------------------------------------------------------*/
.Dropdown:hover .Dropdown-menu {
  display: block;  // set "Dropdown-menu hidden" the popup menu
}

/*---------------------------------------------------------------------------
 Navbar.tsx
---------------------------------------------------------------------------*/
nav {
  font-weight: bolder;
  background: darkgray;
  display: flex;
  ul {
    // background: lightyellow;
    // border: 1px solid green;
    list-style: none;
    margin:  4px;
    padding: 4px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 800px;
  }
  li {
    flex: 3;
  }
}

/*---------------------------------------------------------------------------
 ui.tsx - useClose()
---------------------------------------------------------------------------*/
.Ani_dialog {
  animation: Open .3s ease-in-out; // start and finish slowly(ease), speed up in the middle
  @keyframes Open {
    from { transform: scaleY(0); opacity: 0; }
    to   { transform: scaleY(1); opacity: 1; }
  }
}
.Ani_dialog_close {
  transition-property: transform, opacity, display;
  transition-duration: .3s;
  transition-timing-function: ease-in-out;
  transform: scaleY(0);
  opacity: 0;
/*
  animation: Close .3s ease-in-out forwards;  // forwards - keep the final state
  @keyframes Close {
    from { transform: scaleY(1); opacity: 1; }
    to   { transform: scaleY(0); opacity: 0; }
  }
*/
}

/*---------------------------------------------------------------------------
 toggleDark.tsx - dark mode switch
---------------------------------------------------------------------------*/
.Inner-shadow {
  box-shadow:
    inset 1px 1px 1px rgba(0,0,0,.5),
    inset   0   0 3px rgba(0,0,0,.4);
}
.Ball-shadow {
  box-shadow:
    inset -1px -1px 1px rgba(0,0,0,.5),
             0  1px 1px rgba(0,0,0,.8);
}

/*---------------------------------------------------------------------------
 ProfilePage.tsx - Upload Image
---------------------------------------------------------------------------*/
.UpImage {
  cursor: pointer;
  &:hover {
    opacity: .9;
    background-image:url("//www.gstatic.com/images/icons/material/system/2x/photo_camera_white_24dp.png");
    background-position: center;
    background-repeat: no-repeat;
    background-size: 24px 24px;
    background-color: rgba(0,0,0,.2);
    height: 100%;
  }
}

/*---------------------------------------------------------------------------
 LikeButton.tsx - Like Button Animations
---------------------------------------------------------------------------*/
svg#heart-like {
  cursor: pointer;
  overflow: visible;
  
  #heart-path {
    transform-origin: center;
    animation: animateHeartOut .3s linear forwards;
  }
  #main-circ {
    transform-origin: 29.5px 29.5px;
  }
}

@mixin OvalTransition($x, $y, $duration: .5s, $delay: .3s) {
  transform: scale(0) translate($x, $y);
  transform-origin: 0 0 0;
  transition: $duration transform $delay;
}

.cb-like {
  display: none;

  &:checked+label svg {
    #heart-path {
      transform: scale(0.2);
      fill: #E2264D;
      animation: animateHeart .3s linear forwards .25s;
    }
    #main-circ {
      transition: all 2s;
      animation: animateCircle .3s linear forwards;
      opacity: 1;
    }

    [id^="grp"] {
      opacity: 1;
      transition: .1s all .3s;
    }
    #grp0 {
      #oval1 { @include OvalTransition(0px, -30px); }
      #oval2 { @include OvalTransition(10px,-50px, 1.5s); }
    }
    #grp1 {
      #oval1 { @include OvalTransition(30px,-15px); }
      #oval2 { @include OvalTransition(60px,-15px, 1.5s); }
    }
    #grp2 {
      #oval1 { @include OvalTransition(30px,  0px); }
      #oval2 { @include OvalTransition(60px, 10px, 1.5s); }
    }
    #grp3 {
      #oval1 { @include OvalTransition(30px, 15px); }
      #oval2 { @include OvalTransition(40px, 50px, 1.5s); }
    }
    #grp4 {
      #oval1 { @include OvalTransition(-10px, 20px); }
      #oval2 { @include OvalTransition(-60px, 30px, 1.5s); }
    }
    #grp5 {
      #oval1 { @include OvalTransition(-30px,  0px); }
      #oval2 { @include OvalTransition(-60px, -5px, 1.5s); }
    }
    #grp6 {
      #oval1 { @include OvalTransition(-30px, -15px); }
      #oval2 { @include OvalTransition(-55px, -30px, 1.5s); }
    }
  }
}

@keyframes animateCircle {
  40%  { transform: scale(10); opacity: 1; fill: #DD4688; }
  55%  { transform: scale(11); opacity: 1; fill: #D46ABF; }
  65%  { transform: scale(12); opacity: 1; fill: #CC8EF5; }
  75%  { transform: scale(13); opacity: 1; fill: transparent; stroke: #CC8EF5; stroke-width: .5; }
  85%  { transform: scale(17); opacity: 1; fill: transparent; stroke: #CC8EF5; stroke-width: .2; }
  95%  { transform: scale(18); opacity: 1; fill: transparent; stroke: #CC8EF5; stroke-width: .1; }
  100% { transform: scale(19); opacity: 1; fill: transparent; stroke: #CC8EF5; stroke-width:  0; }
}

@keyframes animateHeart {
  0%   { transform: scale(0.2); }
  40%  { transform: scale(1.2); }
  100% { transform: scale(1);   }
}

@keyframes animateHeartOut {
  0%   { transform: scale(1.4); }
  100% { transform: scale(1);   }
}

/*---------------------------------------------------------------------------
 KaTeX.tsx - patch for katex.min.css
---------------------------------------------------------------------------*/
.katex .mfrac .frac-line {
  border-color: currentColor;   // to patch the conflict with tailwind custom reset styles
}

/*---------------------------------------------------------------------------
 Dropzone.tsx, Preview.tsx - using react-dropzone
---------------------------------------------------------------------------*/
.Drop_cont {
  display: flex;
  flex-direction: column;
  font-family: sans-serif;
}

.Dropzone {
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 20px;
  border-width: 2px;
  border-color: #eeeeee;
  border-style: dashed;
  background-color: #fafafa;
  font-family: monospace;
  font-size: small;
  color: black;
  outline: none;
  transition: border .24s ease-in-out;
  button {
    font-family: sans-serif;
    font-size: medium;
    margin: 8px;
  }
}

/*---------------------------------------------------------------------------
 webcam.tsx
---------------------------------------------------------------------------*/
.Vid-cont {  // container
  position: relative;  // should be relative to contain absolute children
  left: 0;
  top : 0;
  text-align: left;
  width : 100vw;
  height: 100vw;
  font-family: Helvetica, Verdana;
  &::before {
    font-size: small;
    margin: 10px;
    color: DarkGreen;
    content: attr(data-src);
  }
  // z-index: -100;
}

.Vid-fw {  // 
  position: absolute;
  left: 0;
  top : 0;
  width : 100%;
  height: 100%;
  &:hover {
    box-shadow: 0 0 10px 6px Magenta;
  }
  &:focus {
    outline: 0;
  }
  border-radius: 5%;
  border: 2px solid Silver;
  box-shadow: 0 0 10px 6px Cyan;
  object-fit: cover;
  z-index: -10;
  //filter: brightness(2.5);
  //pointer-events: none;
  transition: all 1s ease-in;
}

@mixin btnDemo($borderColor) {
  background-color: Gainsboro;
  border: 2px solid $borderColor;
  color: black;
  padding: 4px 4px;
  font: small "Courier New", sans-serif;
  font-weight: bold;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  font-size: 10px;
}

.Btn-cont {  // button container
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  align-items: flex-end;
  margin-left: 200px;
}

.Test-btn {  // test button
  left: 10px;
  top:  10px;
  padding: 4px;
  margin:  2px;
  border-radius: 10%;
  @include btnDemo(#4caf50);
}

.Test-btn:disabled,
Test-btn[disabled] {
  border: 1px solid olive;
  background-color: darkgray;
  color: SlateGray;
}

/*---------------------------------------------------------------------------
 URLView.tsx
---------------------------------------------------------------------------*/
.YouTubeEmbed {
  width:  500px;
  // height: 300px;  // set it auto
}

/*---------------------------------------------------------------------------
 Custom Scroll css
---------------------------------------------------------------------------*/
// ::-webkit-scrollbar {
//   height: .5rem;
//   width: .5rem;
// }


// /* Track */
// ::-webkit-scrollbar-track {
//   box-shadow: inset 0 0 5px white; 
//   border-radius: 4px;
// }
 
// /* Handle */
// ::-webkit-scrollbar-thumb {
//   background: gray; 
//   border-radius: 3px;
// }

// /* Handle on hover */
// ::-webkit-scrollbar-thumb:hover {
//   background: gray; 

//   max-width: 100%;
//   overflow-x: visible;
// }

/*
::-webkit-scrollbar-track
{
	-webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.3);
	border-radius: 10px;
	background-color: #F5F5F5;
}

::-webkit-scrollbar
{
	width: 12px;
	background-color: #F5F5F5;
}

::-webkit-scrollbar-thumb
{
	border-radius: 10px;
	-webkit-box-shadow: inset 0 0 6px rgba(0,0,0,.3);
	background-color: #555;
}
*/

// .scene {
//   perspective: 600px;
//   display: flex;
// }

// .card {
//   position: relative;
//   transform-style: preserve-3d;
//   transform: transform .5s;
//   .flip {
//     transform: rotateY(-120deg);
//   }
// }
